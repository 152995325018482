<template>
    <div>
        <div v-if="!pageIndexApiDataIsLoading">
            <company-licence-component/>

            <div class="row">
                <div class="col-md-12 m-b-0">
                    <router-link class="btn btn-white m-r-5 text-orange" to="/production"><i class="fa fa-flask fa-fw fa-lg text-orange"></i></router-link>
                </div>
            </div>

            <!--<h1 class="page-header">Usine &Production <small class="lead">.</small></h1>-->
            <hr class="bg-grey-lighter" />

            <div class="row m-b-5">
                <div class="col-md-12 m-b-0">

                </div>
            </div>

            <div v-if="pageIndexApiData != null">
                <div class="row">
                    <div class="col">
                    </div>
                    <div class="col">
                    </div>
                </div>
            </div>
        </div>
        <div class="panel-loader" v-else><span class="spinner-small"></span></div>
    </div>
</template>


<script>
    import pageApi from '../../store/api/production/production-dashboard-page-api'
    import LocalStorage from "../../store/local-storage";
    import CompanyLicenceComponent from '../../components/app/base/CompanyLicenceComponent.vue'

    export default {
        name: 'production-dashboard-page-component',
        components: {
            CompanyLicenceComponent,
        },
        props: {},
        watch: {},
        computed: {},
        methods: {
            //Initialization Methods
            loadPageScripts() {
            },

            //Other Methods
            async getPageIndexApiData() {
                this.pageIndexApiDataIsLoading = true;
                this.pageIndexApiDataErrorMessage = null;
                try {
                    const response = await pageApi.getIndexData();
                    //console.log(response);
                    this.pageIndexApiData = response.data;
                    this.pageIndexApiDataIsLoading = false;
                    this.pageIndexApiDataErrorMessage = null;
                } catch (error) {
                    if (error.response) {
                        //Server responded with a status code that is NOT in the HTTP 200 range
                        //console.log("Headers", error.response.headers);
                        //console.log("Status Code", error.response.status);
                        //console.log("Response Data", error.response.data);
                        if (error.response.status === 401 || error.response.status === 403 || error.response.status === 419) {
                            this.pageIndexApiDataIsLoading = false;
                            this.pageIndexApiDataErrorMessage = error.response.data.message;
                            LocalStorage.clearSessionData();
                            this.$router.push('login');
                        }  else {
                            this.pageIndexApiDataIsLoading = false;
                            this.pageIndexApiDataErrorMessage = error.response.data.message;
                            //
                            this.$swal({
                                icon: 'error',//'info','success','warning','error','question',
                                toast: true,
                                position: 'bottom-end',
                                timer: 3000,
                                showConfirmButton: false,
                                title: 'HTTP ' + error.response.status,
                                text: error.response.data.message,
                            })
                        }
                    } else {
                        this.pageIndexApiDataIsLoading = false;
                        this.pageIndexApiDataErrorMessage = error.message;
                        //
                        this.$swal({
                            icon: 'error',//'info','success','warning','error','question',
                            toast: true,
                            position: 'bottom-end',
                            timer: 3000,
                            showConfirmButton: false,
                            //title: error.message
                            text: error.message,
                        })
                    }
                }
            },
        },
        data: function () {
            return {
                appName: window.appConfig.appName,
                appCurrency: window.appConfig.appCurrency,
                intervalId01: 0,

                //API Data
                //
                pageIndexApiData: null,
                pageIndexApiDataIsLoading: false,
                pageIndexApiDataErrorMessage: null,
            };
        },
        created: function () {
            this.getPageIndexApiData();
        },
        mounted: function () {
            this.loadPageScripts();
        },
        beforeDestroy: function () {
        },
    }
</script>
